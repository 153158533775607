import type { StoreRecord } from "./../services/store";
import type { RESTAPIQueryResponse } from "./../server/utils/query";
import type { IID } from "~/server/models/plugins/object";
import type { StoreMethods } from "~/services/store";
import type { StoreRecordReference, StoreData } from "~/services/store";
import { unwrapReference, toReference } from "~/services/store";
import type { RESTAPIReadDocumentResponse } from "~/server/utils/findById";
import type { RESTAPIDeleteDocumentResponse } from "~/server/utils/findByIdAndDelete";
import { omit } from "lodash-es";

export default defineNuxtPlugin(() => {
	const { $logger } = useNuxtApp();

	const cookie = useCookie(useRuntimeConfig().public.cookieNamespace);

	const headers: HeadersInit = {
		Authorization: cookie.value ? `Bearer ${cookie.value}` : "",
	};

	return {
		provide: {
			restapi: {
				getDocument: async function (reference: StoreRecordReference) {
					const timestamp = Date.now();

					const { collectionName, id } = unwrapReference(reference);

					const promise = $fetch<RESTAPIReadDocumentResponse<IID>>(`/api/${collectionName}/${id}`, {
						headers: headers,
						onRequestError: (error) => {
							$logger.error(error);
						},
					});

					const document = await promise;

					//console.log("RestAPI: getDocument", reference, Date.now() - timestamp);

					if (document.id === id) {
						return toStoreData(reference, document);
					} else {
						throw new Error("Document does not exist: " + collectionName + "/" + id);
					}
				},
				getDocuments: async function (collectionName, filters?, options?) {
					const timestamp = Date.now();

					const documents = await $fetch<RESTAPIQueryResponse<IID>>(`/api/${collectionName}`, {
						query: {
							filters: JSON.stringify(filters),
							options: JSON.stringify(options),
						},
						headers: headers,
						onRequestError: (error) => {
							$logger.error(error);
						},
					});

					//console.log("RestAPI: getDocuments", collectionName, Date.now() - timestamp);

					return documents.map((document) => {
						return toStoreData(toReference(collectionName, document.id), document);
					});
				},
				saveDocument: async function (reference, input) {
					const { collectionName, id } = unwrapReference(reference);

					const document = await $fetch<RESTAPIReadDocumentResponse<IID>>(`/api/${collectionName}/${id}`, {
						headers: headers,
						method: "PATCH",
						body: toRestApiData(input),
						onRequestError: (error) => {
							$logger.error(error);
						},
					});

					if (document.id === id) {
						return toStoreData(reference, document);
					} else {
						throw new Error("Document does not exist: " + collectionName + "/" + id);
					}
				},
				createDocument: async function (collectionName, input) {
					const document = await $fetch<RESTAPIReadDocumentResponse<IID>>(`/api/${collectionName}`, {
						headers: headers,
						method: "POST",
						body: toRestApiData(input),
						onRequestError: (error) => {
							$logger.error(error);
						},
					});

					const reference = toReference(collectionName, document.id);

					return toStoreData(reference, document);
				},
				deleteDocument: async function (reference) {
					const { collectionName, id } = unwrapReference(reference);

					const response = await $fetch<RESTAPIDeleteDocumentResponse>(`/api/${collectionName}/${id}`, {
						headers: headers,
						method: "DELETE",
						onRequestError: (error) => {
							$logger.error(error);
						},
					});

					if (response.id === id) {
						return;
					} else {
						throw new Error("Document does not exist: " + collectionName + "/" + id);
					}
				},
			} as StoreMethods,
		},
	};
});

function toStoreData(reference: StoreRecordReference, document: any): StoreData {
	return {
		...document,
	} as StoreData;
}

function toRestApiData(document: StoreRecord): any {
	return omit(document, ["internals", "id"]);
}
