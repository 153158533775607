import { defineStore } from "pinia";
import type { PingResponse } from "~/server/api/auth/ping.get";
import type { IUserPermissions } from "~/server/models/user";

export const useUser = defineStore("user", () => {
	const id = ref<string | undefined>();
	const role = ref<Roles | undefined>();
	const permissions = ref<IUserPermissions | undefined>();
	const touch = ref<boolean>(false);

	async function ping(): Promise<string> {
		const cookie = useCookie(useRuntimeConfig().public.cookieNamespace);

		const {
			id: _id,
			role: _role,
			permissions: _permissions,
		} = await $fetch<PingResponse>(`/api/auth/ping`, {
			headers: {
				Authorization: cookie.value ? `Bearer ${cookie.value}` : "",
			},
		});

		id.value = _id;
		role.value = _role;
		permissions.value = _permissions;

		return _id;
	}

	return {
		touch,
		ping,
		id,
		role,
		permissions,
	};
});
