import { default as _91id_9310D9U5zzEXMeta } from "/vercel/path0/pages/[id].vue?macro=true";
import { default as componentsRfOnVfXFhhMeta } from "/vercel/path0/pages/components.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as _91id_935ctujFggcfMeta } from "/vercel/path0/pages/dealerships/[id].vue?macro=true";
import { default as createT3asxHJnRzMeta } from "/vercel/path0/pages/dealerships/create.vue?macro=true";
import { default as index8BNaGTEQ0aMeta } from "/vercel/path0/pages/dealerships/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93QGSMUDlr4XMeta } from "/vercel/path0/pages/loans/[id].vue?macro=true";
import { default as create064RYj45asMeta } from "/vercel/path0/pages/loans/create.vue?macro=true";
import { default as indexgw32BCdJkkMeta } from "/vercel/path0/pages/loans/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _91id_93vZhmtgFcyKMeta } from "/vercel/path0/pages/models/[id].vue?macro=true";
import { default as createkhBsXqdVAwMeta } from "/vercel/path0/pages/models/create.vue?macro=true";
import { default as indexMa3L2ekKDEMeta } from "/vercel/path0/pages/models/index.vue?macro=true";
import { default as analyticsYZPoX9wkV4Meta } from "/vercel/path0/pages/pages/[id]/analytics.vue?macro=true";
import { default as editZetndccfJ6Meta } from "/vercel/path0/pages/pages/[id]/edit.vue?macro=true";
import { default as indexSkZ5CljtpIMeta } from "/vercel/path0/pages/pages/[id]/index.vue?macro=true";
import { default as createOQoT9uwAjSMeta } from "/vercel/path0/pages/pages/create.vue?macro=true";
import { default as indexq55wKoeKjQMeta } from "/vercel/path0/pages/pages/index.vue?macro=true";
import { default as _91id_93y2HUxQFlWUMeta } from "/vercel/path0/pages/payment-pages/[id].vue?macro=true";
import { default as create3qk582yhJNMeta } from "/vercel/path0/pages/payment-pages/create.vue?macro=true";
import { default as indexcXGsvjdONpMeta } from "/vercel/path0/pages/payment-pages/index.vue?macro=true";
import { default as index6kWGOy3xmvMeta } from "/vercel/path0/pages/payments/[id]/index.vue?macro=true";
import { default as successzp7z8xQJhbMeta } from "/vercel/path0/pages/payments/[id]/success.vue?macro=true";
import { default as profilekxSCn5bMpQMeta } from "/vercel/path0/pages/profile.vue?macro=true";
import { default as _91id_93hN07yfmrBEMeta } from "/vercel/path0/pages/requests/[id].vue?macro=true";
import { default as createaxMMoyOkTNMeta } from "/vercel/path0/pages/requests/create.vue?macro=true";
import { default as success3b5DY8IdWKMeta } from "/vercel/path0/pages/requests/success.vue?macro=true";
import { default as _91id_93mVN56LKb9SMeta } from "/vercel/path0/pages/users/[id].vue?macro=true";
import { default as create8kvWARNRbrMeta } from "/vercel/path0/pages/users/create.vue?macro=true";
import { default as indexOXtObJcgq9Meta } from "/vercel/path0/pages/users/index.vue?macro=true";
import { default as indexdkgr5gN20kMeta } from "/vercel/path0/pages/vehicles/index.vue?macro=true";
export default [
  {
    name: _91id_9310D9U5zzEXMeta?.name ?? "id",
    path: _91id_9310D9U5zzEXMeta?.path ?? "/:id()",
    meta: _91id_9310D9U5zzEXMeta || {},
    alias: _91id_9310D9U5zzEXMeta?.alias || [],
    redirect: _91id_9310D9U5zzEXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: componentsRfOnVfXFhhMeta?.name ?? "components",
    path: componentsRfOnVfXFhhMeta?.path ?? "/components",
    meta: componentsRfOnVfXFhhMeta || {},
    alias: componentsRfOnVfXFhhMeta?.alias || [],
    redirect: componentsRfOnVfXFhhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/components.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_935ctujFggcfMeta?.name ?? "dealerships-id",
    path: _91id_935ctujFggcfMeta?.path ?? "/dealerships/:id()",
    meta: _91id_935ctujFggcfMeta || {},
    alias: _91id_935ctujFggcfMeta?.alias || [],
    redirect: _91id_935ctujFggcfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dealerships/[id].vue").then(m => m.default || m)
  },
  {
    name: createT3asxHJnRzMeta?.name ?? "dealerships-create",
    path: createT3asxHJnRzMeta?.path ?? "/dealerships/create",
    meta: createT3asxHJnRzMeta || {},
    alias: createT3asxHJnRzMeta?.alias || [],
    redirect: createT3asxHJnRzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dealerships/create.vue").then(m => m.default || m)
  },
  {
    name: index8BNaGTEQ0aMeta?.name ?? "dealerships",
    path: index8BNaGTEQ0aMeta?.path ?? "/dealerships",
    meta: index8BNaGTEQ0aMeta || {},
    alias: index8BNaGTEQ0aMeta?.alias || [],
    redirect: index8BNaGTEQ0aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dealerships/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QGSMUDlr4XMeta?.name ?? "loans-id",
    path: _91id_93QGSMUDlr4XMeta?.path ?? "/loans/:id()",
    meta: _91id_93QGSMUDlr4XMeta || {},
    alias: _91id_93QGSMUDlr4XMeta?.alias || [],
    redirect: _91id_93QGSMUDlr4XMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/loans/[id].vue").then(m => m.default || m)
  },
  {
    name: create064RYj45asMeta?.name ?? "loans-create",
    path: create064RYj45asMeta?.path ?? "/loans/create",
    meta: create064RYj45asMeta || {},
    alias: create064RYj45asMeta?.alias || [],
    redirect: create064RYj45asMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/loans/create.vue").then(m => m.default || m)
  },
  {
    name: indexgw32BCdJkkMeta?.name ?? "loans",
    path: indexgw32BCdJkkMeta?.path ?? "/loans",
    meta: indexgw32BCdJkkMeta || {},
    alias: indexgw32BCdJkkMeta?.alias || [],
    redirect: indexgw32BCdJkkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/loans/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vZhmtgFcyKMeta?.name ?? "models-id",
    path: _91id_93vZhmtgFcyKMeta?.path ?? "/models/:id()",
    meta: _91id_93vZhmtgFcyKMeta || {},
    alias: _91id_93vZhmtgFcyKMeta?.alias || [],
    redirect: _91id_93vZhmtgFcyKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/models/[id].vue").then(m => m.default || m)
  },
  {
    name: createkhBsXqdVAwMeta?.name ?? "models-create",
    path: createkhBsXqdVAwMeta?.path ?? "/models/create",
    meta: createkhBsXqdVAwMeta || {},
    alias: createkhBsXqdVAwMeta?.alias || [],
    redirect: createkhBsXqdVAwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/models/create.vue").then(m => m.default || m)
  },
  {
    name: indexMa3L2ekKDEMeta?.name ?? "models",
    path: indexMa3L2ekKDEMeta?.path ?? "/models",
    meta: indexMa3L2ekKDEMeta || {},
    alias: indexMa3L2ekKDEMeta?.alias || [],
    redirect: indexMa3L2ekKDEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/models/index.vue").then(m => m.default || m)
  },
  {
    name: analyticsYZPoX9wkV4Meta?.name ?? "pages-id-analytics",
    path: analyticsYZPoX9wkV4Meta?.path ?? "/pages/:id()/analytics",
    meta: analyticsYZPoX9wkV4Meta || {},
    alias: analyticsYZPoX9wkV4Meta?.alias || [],
    redirect: analyticsYZPoX9wkV4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pages/[id]/analytics.vue").then(m => m.default || m)
  },
  {
    name: editZetndccfJ6Meta?.name ?? "pages-id-edit",
    path: editZetndccfJ6Meta?.path ?? "/pages/:id()/edit",
    meta: editZetndccfJ6Meta || {},
    alias: editZetndccfJ6Meta?.alias || [],
    redirect: editZetndccfJ6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pages/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexSkZ5CljtpIMeta?.name ?? "pages-id",
    path: indexSkZ5CljtpIMeta?.path ?? "/pages/:id()",
    meta: indexSkZ5CljtpIMeta || {},
    alias: indexSkZ5CljtpIMeta?.alias || [],
    redirect: indexSkZ5CljtpIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createOQoT9uwAjSMeta?.name ?? "pages-create",
    path: createOQoT9uwAjSMeta?.path ?? "/pages/create",
    meta: createOQoT9uwAjSMeta || {},
    alias: createOQoT9uwAjSMeta?.alias || [],
    redirect: createOQoT9uwAjSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pages/create.vue").then(m => m.default || m)
  },
  {
    name: indexq55wKoeKjQMeta?.name ?? "pages",
    path: indexq55wKoeKjQMeta?.path ?? "/pages",
    meta: indexq55wKoeKjQMeta || {},
    alias: indexq55wKoeKjQMeta?.alias || [],
    redirect: indexq55wKoeKjQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93y2HUxQFlWUMeta?.name ?? "payment-pages-id",
    path: _91id_93y2HUxQFlWUMeta?.path ?? "/payment-pages/:id()",
    meta: _91id_93y2HUxQFlWUMeta || {},
    alias: _91id_93y2HUxQFlWUMeta?.alias || [],
    redirect: _91id_93y2HUxQFlWUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payment-pages/[id].vue").then(m => m.default || m)
  },
  {
    name: create3qk582yhJNMeta?.name ?? "payment-pages-create",
    path: create3qk582yhJNMeta?.path ?? "/payment-pages/create",
    meta: create3qk582yhJNMeta || {},
    alias: create3qk582yhJNMeta?.alias || [],
    redirect: create3qk582yhJNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payment-pages/create.vue").then(m => m.default || m)
  },
  {
    name: indexcXGsvjdONpMeta?.name ?? "payment-pages",
    path: indexcXGsvjdONpMeta?.path ?? "/payment-pages",
    meta: indexcXGsvjdONpMeta || {},
    alias: indexcXGsvjdONpMeta?.alias || [],
    redirect: indexcXGsvjdONpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payment-pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6kWGOy3xmvMeta?.name ?? "payments-id",
    path: index6kWGOy3xmvMeta?.path ?? "/payments/:id()",
    meta: index6kWGOy3xmvMeta || {},
    alias: index6kWGOy3xmvMeta?.alias || [],
    redirect: index6kWGOy3xmvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: successzp7z8xQJhbMeta?.name ?? "payments-id-success",
    path: successzp7z8xQJhbMeta?.path ?? "/payments/:id()/success",
    meta: successzp7z8xQJhbMeta || {},
    alias: successzp7z8xQJhbMeta?.alias || [],
    redirect: successzp7z8xQJhbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payments/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: profilekxSCn5bMpQMeta?.name ?? "profile",
    path: profilekxSCn5bMpQMeta?.path ?? "/profile",
    meta: profilekxSCn5bMpQMeta || {},
    alias: profilekxSCn5bMpQMeta?.alias || [],
    redirect: profilekxSCn5bMpQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hN07yfmrBEMeta?.name ?? "requests-id",
    path: _91id_93hN07yfmrBEMeta?.path ?? "/requests/:id()",
    meta: _91id_93hN07yfmrBEMeta || {},
    alias: _91id_93hN07yfmrBEMeta?.alias || [],
    redirect: _91id_93hN07yfmrBEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/requests/[id].vue").then(m => m.default || m)
  },
  {
    name: createaxMMoyOkTNMeta?.name ?? "requests-create",
    path: createaxMMoyOkTNMeta?.path ?? "/requests/create",
    meta: createaxMMoyOkTNMeta || {},
    alias: createaxMMoyOkTNMeta?.alias || [],
    redirect: createaxMMoyOkTNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/requests/create.vue").then(m => m.default || m)
  },
  {
    name: success3b5DY8IdWKMeta?.name ?? "requests-success",
    path: success3b5DY8IdWKMeta?.path ?? "/requests/success",
    meta: success3b5DY8IdWKMeta || {},
    alias: success3b5DY8IdWKMeta?.alias || [],
    redirect: success3b5DY8IdWKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/requests/success.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mVN56LKb9SMeta?.name ?? "users-id",
    path: _91id_93mVN56LKb9SMeta?.path ?? "/users/:id()",
    meta: _91id_93mVN56LKb9SMeta || {},
    alias: _91id_93mVN56LKb9SMeta?.alias || [],
    redirect: _91id_93mVN56LKb9SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: create8kvWARNRbrMeta?.name ?? "users-create",
    path: create8kvWARNRbrMeta?.path ?? "/users/create",
    meta: create8kvWARNRbrMeta || {},
    alias: create8kvWARNRbrMeta?.alias || [],
    redirect: create8kvWARNRbrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexOXtObJcgq9Meta?.name ?? "users",
    path: indexOXtObJcgq9Meta?.path ?? "/users",
    meta: indexOXtObJcgq9Meta || {},
    alias: indexOXtObJcgq9Meta?.alias || [],
    redirect: indexOXtObJcgq9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexdkgr5gN20kMeta?.name ?? "vehicles",
    path: indexdkgr5gN20kMeta?.path ?? "/vehicles",
    meta: indexdkgr5gN20kMeta || {},
    alias: indexdkgr5gN20kMeta?.alias || [],
    redirect: indexdkgr5gN20kMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  }
]