import Bugsnag from "@bugsnag/js";
import { Client } from "@bugsnag/core";
import type { BrowserConfig } from "@bugsnag/browser";
import type { NodeConfig } from "@bugsnag/node";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { useUser } from "~/stores/user";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	const bugsnag = config.public.bugsnag;

	// we check the internal client to prevent the [bugsnag] Bugsnag.start() was called more than once. Ignoring. error
	let client: Client | null = (Bugsnag as any)._client;

	const user = useUser();

	// if the client is null, we start it
	if (client === null) {
		let config: BrowserConfig | NodeConfig = {
			apiKey: bugsnag.apiKey,
			enabledReleaseStages: bugsnag.enabledReleaseStages,
			releaseStage: bugsnag.releaseStage,
			appVersion: bugsnag.appVersion,
			plugins: [new BugsnagPluginVue()],
			onError: (event) => {
				//
				event.errors[0].stacktrace = event.errors[0].stacktrace.map((row) => {
					row.file = row.file.replace("file://", "");
					return row;
				});
				// set the current user
				if (user.id) {
					event.setUser(user.id);
				}
			},
		} as BrowserConfig;

		if (process.server) {
			//bugsnag as NodeConfig;
		} else {
		}

		client = Bugsnag.start(config);
	}

	const vuePlugin = client.getPlugin("vue");
	nuxtApp.vueApp.use(vuePlugin!);

	return {
		provide: {
			bugsnag: client as Client,
		},
	};
});
