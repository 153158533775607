import { useUser } from "./../stores/user";
import MobileDetect from "mobile-detect";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		const userAgent = useRequestHeaders()["user-agent"];
		if (userAgent) {
			const detect = new MobileDetect(userAgent);

			if (detect.mobile()) {
				useUser().touch = true;
			}
		}
	}
});
