export default {
	login: "/login",
	auth: {
		logout: "/auth/logout",
	},
	components: "/components",
	dashboard: "/dashboard",
	profile: "/profile",
	users: {
		index: "/users",
		create: "/users/create",
		edit: "/users/:id",
	},
	pages: {
		index: "/pages/",
		create: "/pages/create",
		edit: {
			edit: "/pages/:id/edit",
			analytics: "/pages/:id/analytics",
		},
	},
	paymentPages: {
		index: "/payment-pages",
		create: "/payment-pages/create",
		edit: "/payment-pages/:id",
	},
	vehicles: {
		index: "/vehicles",
		create: "/vehicles/create",
		edit: "/vehicles/:id",
	},
	models: {
		index: "/models",
		create: "/models/create",
		edit: "/models/:id",
	},
	dealerships: {
		index: "/dealerships/",
		create: "/dealerships/create",
		edit: "/dealerships/:id",
	},
	loans: {
		index: "/loans/",
		create: "/loans/create",
		edit: "/loans/:id",
		filters: {
			processing: "/loans?filter=processing",
			approved: "/loans?filter=approved",
		},
	},
	requests: {
		create: "/requests/create",
		edit: "/requests/:id",
		success: "/requests/success",
	},
} as const;
