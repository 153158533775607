import { v4 as uuidv4 } from "uuid";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		if (to.name === "id") {
			const cookie = useCookie("__session_id");
			if (!cookie.value) {
				cookie.value = uuidv4();
			}
		}
	}
});
