import { Client } from "@bugsnag/core";

export default defineNuxtPlugin(() => {
	const nuxtApp = useNuxtApp();
	const bugsnag = nuxtApp.$bugsnag as Client;

	return {
		provide: {
			logger: {
				error(error: any) {
					console.error(error);
					bugsnag.notify(error);
				},
				log() {},
				success() {},
				warn() {},
			},
		},
	};
});
