import routes from "./../utils/routes";
import { useUser } from "./../stores/user";

const logoutRoute = routes.auth.logout;
const entryRoute = routes.dashboard;

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		if (to.path.includes("auth") || to.path.includes("api")) {
			return;
		}

		if (to.name === "id" || to.name === "payments-id" || to.name === "payments-success" || to.name === "requests-id" || to.name === "requests-create" || to.name === "requests-success") {
			return;
		}

		const cookie = useCookie(useRuntimeConfig().public.cookieNamespace);

		if (cookie && to.path === "/") {
			return navigateTo(routes.dashboard);
		}

		if (to.path === routes.login) {
			// If user has a cookie session and visiting login, redirect to companies page
			if (cookie.value) {
				return navigateTo(entryRoute);
			}
		} else {
			// If user has no cookie session and visiting any page other than login, redirect to login
			if (!cookie.value) {
				return navigateTo(routes.login);
			} else {
				try {
					const { ping } = useUser();
					await ping();
				} catch (error) {
					console.error(error);
					return navigateTo(logoutRoute);
				}
			}
		}
	}
});
